import { useContext, createRef, useState, useEffect, FC } from "react";
import "./Portfolio.css";
import ScrollableAnchor from "react-scrollable-anchor";

import ImgGallery from "./ImgGallery";
import SiteGallery from "./SiteGallery";

import { LangContext, ThemeContext, ScrollContext } from "../../utils/contexts";
import { imgGallery, siteGallery } from "../../utils/arrays";

type Props = {
  openPopup: (index: number) => void;
  isSiteGallery: boolean;
  setSiteGallery: () => void;
  setImgGallery: () => void;
};

const Portfolio: FC<Props> = ({
  openPopup,
  isSiteGallery,
  setSiteGallery,
  setImgGallery
}) => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);
  const scrollCoor = useContext(ScrollContext);

  const portfolioRef = createRef();
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    //@ts-ignore
    if (portfolioRef.current.offsetTop === 0) {
      return;
      //@ts-ignore
    } else if (portfolioRef.current.offsetTop <= scrollCoor + 600) {
      setMount(true);
    }
  }, [portfolioRef, scrollCoor]);

  return (
    <section
      className={`portfolio ${
        theme ? "background-color-dark" : "background-color-light"
      } ${isMount ? "mounted-component" : "hidden-component"}`}
      //@ts-ignore
      ref={portfolioRef}
    >
      <div className="portfolio__section-container">
        <div className="portfolio__text-container">
          <ScrollableAnchor id={"portfolio"}>
            <h2 className="portfolio__heading section__heading">
              {language.portfolio}
            </h2>
          </ScrollableAnchor>
          <ul
            className={`portfolio__buttons ${
              theme ? "" : "portfolio__buttons_theme_light"
            }`}
          >
            <li className="portfolio__button-item">
              <button
                type="button"
                className={`portfolio__button ${
                  isSiteGallery ? "" : "portfolio__button_active"
                }`}
                onClick={setImgGallery}
              >
                {language.design}
              </button>
            </li>
            <li className="portfolio__button-item">
              <button
                type="button"
                className={`portfolio__button ${
                  !isSiteGallery ? "" : "portfolio__button_active"
                }`}
                onClick={setSiteGallery}
              >
                {language.webDev}
              </button>
            </li>
          </ul>
          <p className="portfolio__tagline">{language.portfolioSubline}</p>
        </div>
        <div className="portfolio__gallery-box">
          <div className="portfolio__button-container">
            <button
              className={`portfolio__gallery-button ${
                isSiteGallery ? "" : "portfolio__gallery-button_active"
              } ${theme ? "" : "portfolio__gallery-button_theme_light"}`}
              type="button"
              onClick={setImgGallery}
            />
            <button
              className={`portfolio__gallery-button ${
                !isSiteGallery ? "" : "portfolio__gallery-button_active"
              } ${theme ? "" : "portfolio__gallery-button_theme_light"}`}
              type="button"
              onClick={setSiteGallery}
            />
          </div>
          <ul className="portfolio__gallery-container">
            {isSiteGallery
              ? siteGallery.map((card, index) => (
                  <SiteGallery card={card} key={index} />
                ))
              : imgGallery.map((card, index) => (
                  <ImgGallery
                    card={card}
                    key={index}
                    findImage={() => openPopup(index)}
                  />
                ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
