import { useContext, createRef, useState, useEffect, FC } from "react";
import "./Skills.css";

import Skill from "./Skill";
import { motion } from "framer-motion";

import { createSkillsData } from "../../utils/arrays";
import { LangContext, ThemeContext, ScrollContext } from "../../utils/contexts";

const Skills: FC = () => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);
  const scrollCoor = useContext(ScrollContext);

  const skillsRef = createRef<HTMLDivElement | null>();
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (skillsRef?.current.offsetTop <= scrollCoor + 600) {
      setMount(true);
    }
  }, [skillsRef, scrollCoor]);

  const listData = createSkillsData(language);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const listItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  };

  return (
    <section
      className={`skills ${
        theme ? "background-color-dark" : "background-color-light"
      } ${isMount ? "mounted-component" : "hidden-component"}`}
      // @ts-ignore
      ref={skillsRef}
    >
      <div className="skills__section-container">
        {isMount && (
          <motion.ul
            className="skills__list"
            variants={container}
            initial="hidden"
            animate="show"
          >
            {listData.map((card, i) => (
              <Skill card={card} key={i} variants={listItem} theme={theme} />
            ))}
          </motion.ul>
        )}
        <div className="skills__text-container">
          <h2 className="skills__tagline section__name">{language.skills}</h2>
          <h3 className="skills__heading section__heading">
            {language.myTechStach}
          </h3>
          <p className="skills__subheading">{language.skillsSubline}</p>
        </div>
      </div>
    </section>
  );
};

export default Skills;
