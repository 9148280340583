import { FC, useContext, createRef, useState, useEffect } from "react";
import "./Services.css";

import designIcon from "../../images/icons/services/design.svg";
import webIcon from "../../images/icons/services/webDev.svg";

import { LangContext, ThemeContext, ScrollContext } from "../../utils/contexts";

type Props = {
  setImgGallery: () => void;
  setSiteGallery: () => void;
};

const Services: FC<Props> = ({ setImgGallery, setSiteGallery }) => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);
  const scrollCoor = useContext(ScrollContext);

  const servicesRef = createRef();
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (servicesRef.current.offsetTop <= scrollCoor + 600) {
      setMount(true);
    }
  }, [servicesRef, scrollCoor]);

  return (
    <section
      className={`services animate__animated ${
        isMount ? "mounted-component" : "hidden-component"
      }`}
      // @ts-ignore
      ref={servicesRef}
    >
      <div className="services__text-container">
        <h2 className="services__tagline section__name">{language.services}</h2>
        <h3 className="service__heading section__heading">
          {language.servicesQuestion}
        </h3>
        <p className="services__paragraph">{language.servicesSubline}</p>
      </div>
      <ul className="services__list">
        <li
          className={`services__list-item ${
            theme ? "" : "services__list-item_theme_light"
          }`}
        >
          <div className="services__icon-container">
            <img
              src={designIcon}
              className="services__icon"
              alt="services icon"
            />
          </div>
          <h4 className="services__list-heading">{language.serviceDesign}</h4>
          <p className="services__list-text">{language.serviceDesignSubline}</p>
          <a
            className={`services__list-link ${
              theme ? "" : "services__list-link_theme_light"
            }`}
            href="#portfolio"
            onClick={setSiteGallery}
          >
            {language.learnMore}
          </a>
        </li>
        <li
          className={`services__list-item ${
            theme ? "" : "services__list-item_theme_light"
          }`}
        >
          <div className="services__icon-container">
            <img src={webIcon} className="services__icon" alt="services icon" />
          </div>
          <h4 className="services__list-heading">{language.serviceWeb}</h4>
          <p className="services__list-text">{language.serviceWebSubline}</p>
          <a
            className={`services__list-link ${
              theme ? "" : "services__list-link_theme_light"
            }`}
            href="#portfolio"
            onClick={setImgGallery}
          >
            {language.learnMore}
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Services;
