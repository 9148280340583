import { FC, useContext } from "react";
import "./Header.css";
import ScrollableAnchor from "react-scrollable-anchor";
import { LangContext, ThemeContext } from "../../utils/contexts";

import ybLogoWhite from "../../images/logos/yb_logo_white.png";
import ybLogoBlack from "../../images/logos/yb_logo_black.png";
import mainPicture from "../../images/main_picture_alt.png";

import mailIconWhite from "../../images/icons/mail_icon.svg";
import mailIconBlack from "../../images/icons/mail_icon_black.svg";

import colibriLogoBlack from "../../images/logos/colibri_blk.png";
import colibriLogoWhite from "../../images/logos/colibri_white.png";

import digitalOasisLogoBlack from "../../images/logos/digital_oasis_colored.png";
import digitalOasisLogoWhite from "../../images/logos/digital_oasis_purple.png";

import apelsinLogoBlack from "../../images/logos/apelsin_colored_black.png";
import apelsinLogoWhite from "../../images/logos/apelsin_colored_white.png";

type Props = {
  changeLanguage: () => void;
  changeTheme: () => void;
};

const Header: FC<Props> = ({ changeLanguage, changeTheme }) => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);

  return (
    <header
      className={`header ${
        theme ? "background-color-dark" : "background-color-light"
      }`}
    >
      <div className="header__container">
        <ul className="header__menu">
          <li className="header__menu-item">
            <ScrollableAnchor id={"header"}>
              <img
                src={theme ? ybLogoWhite : ybLogoBlack}
                className="header__main-logo hvr-shrink"
                alt="Main Logo"
              />
            </ScrollableAnchor>
          </li>
          <li className="header__menu-item">
            <a
              href={`mailto:${language.mail}`}
              className="mailto hvr-underline-reveal"
            >
              <img
                src={theme ? mailIconWhite : mailIconBlack}
                className="header__icon"
                alt="mail icon"
              />
              {language.mail}
            </a>
          </li>
        </ul>
        <div className="header__button-container">
          <button
            className="header__lang-switch hvr-underline-from-center"
            onClick={changeLanguage}
          >
            {language.lang}
          </button>
          <input
            className="header__check"
            type="checkbox"
            id="theme"
            name="theme"
            onClick={changeTheme}
            checked={theme}
            readOnly
          />
          <label className="header__check-label" htmlFor="theme" />
        </div>
      </div>
      <div className="header__content-box">
        <div className="header__text-container">
          <p className="header__paragraph">{language.headerTagline}</p>
          <h1 className="header__name-heading">
            {`${language.headerHi} `}
            <span className="blue-coloring">{language.headerName}</span>
          </h1>
          <p className="header__about-me">{language.headerLine}</p>
          <p className="header__about-me">{language.headerSubline}</p>
          <ul className="header__links">
            <li className="header__link-item">
              <a className="header__link" href="#portfolio">
                {language.headerGalleryButton}
              </a>
            </li>
            <li className="header__link-item">
              <a className="header__link" href="#contact">
                {language.headerContact}
              </a>
            </li>
          </ul>
        </div>
        <img
          src={mainPicture}
          alt="that's me!"
          className="header__main-image"
        />
      </div>
      <div className="header__logo-container">
        <h2 className="header__logo-text">{language.headerLogos}</h2>
        <ul className="header__logo-list">
          <li className="header__logo-list-item">
            <img
              src={theme ? apelsinLogoWhite : apelsinLogoBlack}
              alt="Apelsin Logo"
              className="header__logo-item hvr-grow-rotate"
            />
          </li>
          <li className="header__logo-list-item">
            <img
              src={theme ? colibriLogoWhite : colibriLogoBlack}
              alt="Colibri Logo"
              className="header__logo-item header__logo-item_middle hvr-grow-rotate"
            />
          </li>
          <li className="header__logo-list-item">
            <img
              src={theme ? digitalOasisLogoBlack : digitalOasisLogoWhite}
              alt="Digital Oasis Logo"
              className="header__logo-item hvr-grow-rotate"
            />
          </li>
        </ul>
      </div>
      <div className="header__bg-container header__bg-container_left" />
      <div className="header__bg-container header__bg-container_right" />
    </header>
  );
};

export default Header;
