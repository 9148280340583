export const links = {
  github: "https://github.com/YunesB",
  instagram: "https://www.instagram.com/xkrasavax"
};

export const textsEN = {
  lang: "RU",

  click: "Click",
  phone: "+X XXX XXX XX XX",
  mail: "yunesb.inc@gmail.com",
  address: "International connect",
  showMore: "Show More",
  showLess: "Show Less",

  headerTagline: "I AM A DESIGNER / FRONT-END DEVELOPER",
  headerHi: "Hi, my name is",
  headerName: "Yunes.",
  headerLine:
    "Freelance designer and frontend developer, have been in digital industry for more than 3 years. I create web-sites from scratch and can create unqiue corporate style.",
  headerSubline: `Let's create something extraordinary!`,
  headerGalleryButton: "My Work",
  headerContact: `Let's talk`,
  headerLogos: "TRUSTED BY EXPERTS AT",

  services: "Services",
  servicesQuestion: "What I do?",
  servicesSubline:
    "Depending on your needs, I can solve complex design problems and also create a web-page / web-application.",
  serviceDesign: "Graphic Design",
  serviceStyle: "Corporate Style",
  serviceWeb: "Frontend Development",

  serviceDesignSubline:
    "Digital design of logos, banners, social network posts.",
  serviceStyleSubline:
    "Making your company stand out via unique corporate style.",
  serviceWebSubline:
    "Frontend coding of both: simple sites and complex web-applications.",

  portfolio: "Portfolio",
  design: "Design",
  webDev: "Web-Dev",
  portfolioSubline:
    "My personal favorite projects are presented in this section. Take a look at my work in different spheres by navigating through the links above. Unfortunately, I cannot publish most of my commercial projects, so here is the scope of my works not covered by an NDA.",

  workinWith: "Working with",
  experience: "Experience",
  experienceSubline:
    "I have more than 3 years of experience in digital industry and have been working with various software. Can work in Russian / English language equally.",
  experienceButton: "Download Resume",
  experienceDesignDate: "2017 - Present",
  experienceDesignHeader: "Graphic Designer",
  experienceDesignBody:
    "A freelance Graphic Designer for multiple projects. I work with: Adobe Photoshop, Adobe Flash, Corel Draw, Autodesk Sketchbook. Can draw illustrations, upon request.",
  experienceWebDate: "2020 - Present",
  experienceWebHeader: "Frontend Developer",
  experienceWebBody:
    "Currently working as a full-time Frontend Developer. Working mostly with commercial projects. My tech stack is: React + Ant-Design or Tailwind.",

  experienceAdditionalInfo: [
    "Developed an ecology-related web application, where login, registration, profile page and online payments system were implemented by me.",

    "Was participating in developing large scale Education-related system for one of the largest local oil companies. I've built multiple pages along with introduction of complex logic of migrations and training-reporting.",

    "Was working on purchase standardization portal and implemented new features and finished the work few weeks before the deadline. Was able to implement several new pages along with subcontracting logic adjustments.",

    "Designed in Figma and re-written internal company portal using React framework, adding all essential features, pages and integration with backend. Along with these tasks, I had a chance to work as a Junior PM and introduced stories and corresponding tasks to the workflow.",

    "Have worked on large scale intranet portal for one of the biggest local malls. Was working with legacy code based on Redux Saga and custom framework. Was able to finish way before the deadline.",

    "Created new documentation for all projects listed above.",

    "Worked with Quality Assurance to get new pages/products tested, and addressed any issues within 48 hours.",

    "Maintained graphic standards and branding throughout the products interfaces."
  ],

  experienceGeneralDate: "2016 - 2020",
  experienceGeneralHeader: "Project Management",
  experienceGeneralBody:
    "Managed multiple projects for International Organizations, including the British Council, the United States Consulate General and etc. Has also managed an outsource project for Adidas.",

  backend: "Backend",
  graphicDesign: "Graphic Design",
  brainstorming: "Brainstorming",
  execution: "Execution",

  skills: "Skills",
  myTechStach: "My Tech Stack",
  skillsSubline:
    "Here are some highlights of the skills I have obtained during my work in the industry",

  stayConnected: "Stay Connected",
  whatsNext: `What's next?`,
  whatsNextSubline: "Please follow these simple steps to get in touch with me:",
  filout: "filout",
  filoutSubline:
    "the simple contact form below. The more info you will give the better.",
  getStarted: "Get Started",
  check: "Check",
  checkSubline:
    "your inbox within 24 Hours. Look through the brief and make sure that the data is correct.",
  learnMore: "Learn more",
  secondTooltip:
    "Please, carefully read through all fields within the file attached. Also, please devote special attention to fields where you need to indicate your personal preferences. In case if you wish to work on a website, please provide any comments regarding code-writing.",
  thirdTooltip:
    "Even if you find it hard to fill in certain fileds, please either contact me or send the file whenever you feel that you have provided as much information as possible.",

  news: "News",
  getNewsFeed: "Get News Feed",
  newsTooltip:
    "Here are some latest news about Web-Dev (available only in English)",

  submit: "Submit",
  submitSubline:
    "filled-in brief to my email. I will revert to you with the estimate cost of the project as soon as possible.",

  letsDiscuss: `Let's discuss your project`,
  contactFind: "you know how to find me",
  contact: "Contact",
  distantWorking: "Working Distantly",
  getInTouch: "Get in touch",
  callMeNow: "Call me now",
  contactMe: "Fill in the fields below",
  letsCreate: `Ready to get started? Let's create something extraordinary!`,
  contactSubline:
    "Please provide as more information as possible. Before submitting the request, please double check if the indicated email is correct.",

  yourName: "Your Name:",
  yourMail: "Your Mail:",
  yourMessage: "Message:",
  sendMessage: "Send Message",

  onSubmitMessage: "The message was sent. Thanks!",

  VALIDATION: {
    EMPTY_FIELD: "Plese fill in this field",
    SHORT_NAME: "Please indicate your full name",
    SHORT_MESSAGE: "The message should contain at least 3 characters",
    INCORRECT_EMAIL: "Please indicate a correct email"
  },

  loading: "Loading..."
};

export const textsRU = {
  lang: "EN",

  click: "Клик",
  phone: "+X XXX XXX XX XX",
  mail: "yunesb.inc@gmail.com",
  address: "Глобально",
  showMore: "Детали",
  showLess: "Скрыть",

  headerTagline: "Разработчик Фронтэнда / Веб-разработчик",
  headerHi: "Привет, меня зовут",
  headerName: "Юнес.",
  headerLine:
    "Я дизайнер с опытом, а также начинающий веб-разработчик. Занимаюсь созданием сайтов с нуля, а также подбираю и создаю уникальный фирменный стиль.",
  headerSubline: `Давайте создадим что-нибудь необычное!`,
  headerGalleryButton: "Мои работы",
  headerContact: `Связаться со мной`,
  headerLogos: "Мне доверяют эксперты:",

  services: "Услуги",
  servicesQuestion: "Как я могу Вам помочь?",
  servicesSubline:
    "Я могу решить комплексные задачи по дизайну, а также спроектировать и написать сайт / веб-приложение",

  serviceDesign: "Дизайн",
  serviceStyle: "Фирменный стиль",
  serviceWeb: "Фронтэнд разработка",

  serviceDesignSubline:
    "Я разрабатываю фирменный стиль, дизайн логотипов, работаю с типографикой. Также работаю с оформлением постов для социальных сетей.",
  serviceStyleSubline:
    "Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi",
  serviceWebSubline:
    "Могу написать сайт с нуля как на чистом JS, так и на React. Умею работать с бэкендом и БД если есть такая необходимость.",

  portfolio: "Портфолио",
  design: "Дизайн",
  webDev: "Веб-дев",
  portfolioSubline:
    "В этом блоке представлены лучшие, на мой взгляд, мои работы. Переключайтесь между дизайном / веб-разработкой через кнопки выше. К сожалению, я не могу выкладывать большинство своих коммерческих проектов, все проекты, представленные в данной подборке не попадают под соглашение о неразглашении (NDA).",

  workinWith: "Работа",
  experience: "С опытом",
  experienceSubline:
    "В этой секции представлены вырезки из моего резюме. В случае необходимости, я с радостью предоставлю более подробную информацию по запросу.",
  experienceButton: "Загрузить резюме",
  experienceDesignDate: "2017 - По настоящее время",
  experienceDesignHeader: "Графический Дизайнер",
  experienceDesignBody:
    "Работаю в разном софте: Adobe Photoshop, Corel Draw, Autodesk Sketchbook, Adobe Flash. Специализируюсь на разработке логотипов и создании фирменного стиля.",
  experienceWebDate: "2020 - По настоящее время",
  experienceWebHeader: "Веб-разработчик",
  experienceWebBody:
    "На данный момент я работаю Фронтенд Разработчиком на полной ставке и паралелльно занимаюсь заказами на фрилансе. Мой основной фреймворк - ReactJS.",

  experienceAdditionalInfo: [
    "Разработал веб-приложение, связанное с посадкой деревьев, где была реализована логика логина, регистрации, страница пользователя и была интегрирована система онлайн платежей.",

    "Учавствовал в разработке крупного внутреннего портала, связанного с дополнительным образованием, заказчиком которого являлась одна из крупнейших нефтедобывающих компаний Казахстана. Со своей стороны, я занимался написанием нескольких абсолютно новых страниц с автономной логикой, а также работал над доработкой существующих систем.",

    "Работал над веб-приложением для стандартизации работы с поставщиками, в котором мной был добавлен новый функционал, связанный с  внедрением кадровых профилей и возможности нанимать и увольнять активных суб-подрядчиков. Проект был сдан задолго до дедлайна.",

    "Дизайнил в Фигме и переписывал внутренний портал компании, добавил все страницы, переписал логику и общий UI/UX. Также, в рамках данного проекта исполнял роль Младшего Продакт Менеджера, писал стори и обсуждал бизнес логику с заказчиком.",

    "Работал над крупной админ-панелью для одного из крупнейших моллов в Алматы. Проект был интересен тем, что в нем был кастомный фреймворк, переплетенный с Redux Saga и в целом много легаси кода.",

    "Писал документацию для всех вышеперечисленных проектов.",

    "Работал с отделом Quality Assurance, в рамках тестирования и срочного релиза избранных новый фичей.",

    "Соблюдал стандарты графики и бренда во всех перечисленных интерфейсах."
  ],

  experienceGeneralDate: "2016 - 2020",
  experienceGeneralHeader: "Ведение проектов",
  experienceGeneralBody:
    "Работа на международные организации, в том числе: Британский Совет при Посольстве Великобритании, Генеральное Консульство США в Казахстане.",

  backend: "Бэкэнд",
  graphicDesign: "Граф. Дизайн",
  brainstorming: "Креатив и подбор идей",
  execution: "Исполнительность",

  skills: "Навыки",
  myTechStach: "Мой стэк технологий",
  skillsSubline: "Хайлайты того, что я умею.",

  stayConnected: "Оставайтесь на связи",
  whatsNext: `Что дальше?`,
  whatsNextSubline:
    "Для начала работы со мной, пожалуйста, выполните 3 простых шага.",
  filout: "Заполните",
  filoutSubline:
    "простую форму ниже. Это поможет в целом понять, что именно требуется и как быстро я смогу осуществить указанную работу.",
  getStarted: "К форме",
  check: "Проверьте",
  checkSubline:
    "свою почту, Вам должно было прийти от меня письмо. Не забудьте проверить наличие приложенных файлов.",
  learnMore: "Узнать больше",
  secondTooltip:
    "Пожалуйста, внимательно ознакомьтесь со всеми полями во вложенном файле. Особое внимание стоит уделить полям, где Вы указываете предпочтения по разработке / дизайну. В случае, если у Вас заказ на создание сайта, пожалуйста, не забудьте указать предпочтения по написанию кода.",
  thirdTooltip:
    "Даже если какие-либо поля не заполнены, я буду рад помочь в заполнении. Отпарвляйте файл тогда, когда сочтете, что указали исчерпывающую информацию.",

  news: "Новости",
  getNewsFeed: "Будьте в курсе",
  newsTooltip:
    "Тут отображаются последние новости в мире Веб-разработки (только на английском)",

  submit: "Отправьте",
  submitSubline:
    "заполненный бриф мне на почту, я произведу расчеты сроков и обязательно Вам отвечу.",

  letsDiscuss: `Давайте обсудим Ваш проект!`,
  contactFind: "Обращайтесь по данным ниже",
  contact: "Контакты",
  distantWorking: "Дистанционная работа",
  getInTouch: "Пишите на почту",
  callMeNow: "Звоните / пишите в WA",
  contactMe: "Заполните форму ниже и я сам свяжусь с Вами!",
  letsCreate: `Готовы начать работу? Давайте создадим что-то необычное!`,
  contactSubline:
    "Чем подробнее Вы опишете запрос, тем лучше я пойму, что именно Вам требуется и как лучше подойти к решению поставленной задачи. Перед отправкой не забудьте проверить корректность указанного имейла, поскольку именно туда будет отправлена вся последующая информация.",

  yourName: "Ваше Имя:",
  yourMail: "Ваш Email:",
  yourMessage: "Ваше сообщение:",
  sendMessage: "Отправить",

  onSubmitMessage: "Сообщение отправлено. Спасибо!",

  VALIDATION: {
    EMPTY_FIELD: "Пожалуйста, заполните это поле",
    SHORT_NAME: "Пожалуйста, введите более полное имя",
    SHORT_MESSAGE: "Сообщение должно содержать не менее 3 символов",
    INCORRECT_EMAIL: "Пожалуйста, введите корректный email"
  },

  loading: "Загрузка..."
};
