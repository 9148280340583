import { useEffect, useState, useContext, FC } from "react";
import "./Popup.css";
import loading from "../../images/icons/loading.svg";
import { imgGallery } from "../../utils/arrays";
import { LangContext } from "../../utils/contexts";

type Props = {
  isOpen: boolean;
  imageIndex: number | null;
  closePopup: () => void;
};

const Popup: FC<Props> = ({ isOpen, imageIndex, closePopup }) => {
  const language = useContext(LangContext);
  const [currentImage, setCurrentImage] = useState({
    image: loading,
    name: language.loading
  });
  const [isImageLoaded, setImageLoaded] = useState(false);

  const handleClosePopup = (evt: any) => {
    if (!evt.target.closest(".popup__img-container")) {
      closePopup();
    }
  };

  const handleImageLoading = () => {
    if (currentImage.image === loading) {
      return;
    } else {
      setImageLoaded(true);
    }
  };

  useEffect(() => {
    if (imageIndex === null) {
      setCurrentImage({
        image: loading,
        name: language.loading
      });
    } else {
      setCurrentImage({
        image: imgGallery[imageIndex].image,
        name: imgGallery[imageIndex].name
      });
    }
  }, [imageIndex, language]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  return (
    <div
      className={`popup ${isOpen ? "popup_opened" : ""}`}
      onClick={handleClosePopup}
    >
      <div className="popup__img-container">
        <img
          src={currentImage.image}
          alt={currentImage.name}
          className={`popup__image hvr-bounce-in ${
            !isImageLoaded ? "popup__loading-image" : ""
          }`}
          onLoad={handleImageLoading}
        />
        <button className="popup__close" type="button" onClick={closePopup} />
        <h3 className="popup__img-subline">{currentImage.name}</h3>
        <div className="popup__color-box" />
      </div>
    </div>
  );
};

export default Popup;
