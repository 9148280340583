import { useState, useEffect, FC } from "react";

import "./App.css";
import "./Transitions.css";

import { LangContext, ScrollContext, ThemeContext } from "../../utils/contexts";

import Header from "../Header/Header";
import Services from "../Services/Services";
import Portfolio from "../Portfolio/Portfolio";
import Experience from "../Experience/Experience";
import Skills from "../Skills/Skills";
import WhatsNext from "../WhatsNext/WhatsNext";
import Contact from "../Contact/Contact";
import Popup from "../Popup/Popup";
import Footer from "../Footer/Footer";
import MobileBar from "../MobileBar/MobileBar";

import { textsEN, textsRU } from "../../utils/texts";

const App: FC = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDarkTheme, setDarkTheme] = useState<boolean>(true);
  const [isLanguageEng, setLanguageEng] = useState<boolean>(true);
  const [isSiteGallery, setSiteGallery] = useState<boolean>(true);
  const [language, setLanguage] = useState(textsRU);

  const [scrollCoor, setScrollCoor] = useState<number>(0);
  const [imageIndex, setImageIndex] = useState<null | number>(null);

  const handleSetCoor = () => {
    setScrollCoor(window.scrollY);
  };

  useEffect(() => {
    window.onscroll = handleSetCoor;
  }, [scrollCoor]);

  const openPopup = (index: number | null) => {
    setImageIndex(index);
    setModalOpen(true);
  };

  useEffect(() => {
    if (isLanguageEng) {
      setLanguage(textsEN);
    } else {
      setLanguage(textsRU);
    }
  }, [isLanguageEng]);

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setDarkTheme(true);
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: light)").matches
    ) {
      setDarkTheme(false);
    } else {
      setDarkTheme(true);
    }
  }, []);

  return (
    <ThemeContext.Provider value={isDarkTheme}>
      <LangContext.Provider value={language}>
        <div className={`page ${isDarkTheme ? "" : "page_theme_light"}`}>
          <div className="content">
            <Header
              changeTheme={() => setDarkTheme(!isDarkTheme)}
              changeLanguage={() => setLanguageEng(!isLanguageEng)}
            />
            <ScrollContext.Provider value={scrollCoor}>
              <Services
                setImgGallery={() => setSiteGallery(false)}
                setSiteGallery={() => setSiteGallery(true)}
              />
              <Portfolio
                isSiteGallery={isSiteGallery}
                setImgGallery={() => setSiteGallery(false)}
                setSiteGallery={() => setSiteGallery(true)}
                openPopup={openPopup}
              />
              <Experience />
              <Skills />
              <WhatsNext />
              <Contact />
            </ScrollContext.Provider>
            <Footer />
          </div>
          <Popup
            isOpen={isModalOpen}
            closePopup={() => setModalOpen(false)}
            imageIndex={imageIndex}
          />
          {!isModalOpen && (
            <MobileBar
              changeTheme={() => setDarkTheme(!isDarkTheme)}
              changeLanguage={() => setLanguageEng(!isLanguageEng)}
            />
          )}
        </div>
      </LangContext.Provider>
    </ThemeContext.Provider>
  );
};

export default App;
