import { useContext, createRef, useState, useEffect, FC } from "react";
import "./WhatsNext.css";

import { LangContext, ThemeContext, ScrollContext } from "../../utils/contexts";

const WhatsNext: FC = () => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);
  const scrollCoor = useContext(ScrollContext);

  const nextRef = createRef();
  const [isMount, setMount] = useState(false);

  const [isSecondTooltipOpen, setSecondTooltipOpen] = useState(false);
  const [isThirdTooltipOpen, setThirdTooltipOpen] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (nextRef.current.offsetTop <= scrollCoor + 600) {
      setMount(true);
    }
  }, [nextRef, scrollCoor]);

  const closeTooltip = (evt: any) => {
    if (!evt.target.closest(".next__button")) {
      setSecondTooltipOpen(false);
      setThirdTooltipOpen(false);
    }
  };

  return (
    <section
      className={`next ${
        theme ? "background-color-dark" : "background-color-light"
      } ${isMount ? "mounted-component" : "hidden-component"}`}
      // @ts-ignore
      ref={nextRef}
      onClick={(evt) => closeTooltip(evt)}
    >
      <div className="next__text-container">
        <p className="next__tagline section__name">{language.stayConnected}</p>
        <h2 className="next__heading section__heading">{language.whatsNext}</h2>
        <p className="next__text">{language.whatsNextSubline}</p>
      </div>
      <ul className="next__list">
        <li
          className={`next__list-item ${
            theme ? "" : "next__list-item_theme_light"
          }`}
        >
          <p className="next__number">01</p>
          <div className="next__list-box">
            <p className="next__action-word">{language.filout}</p>
            <p className="next__explanation">{language.filoutSubline}</p>
            <a href="#contact" className={`next__link`}>
              {language.getStarted}
            </a>
          </div>
        </li>
        <li
          className={`next__list-item ${
            theme ? "" : "next__list-item_theme_light"
          }`}
        >
          <p className="next__number">02</p>
          <div className="next__list-box">
            <p className="next__action-word">{language.check}</p>
            <p className="next__explanation">{language.checkSubline}</p>
            <button
              type="button"
              className={`next__button`}
              onClick={() => setSecondTooltipOpen(!isSecondTooltipOpen)}
            >
              {language.learnMore}
              <div
                className={`next__tooltip ${
                  isSecondTooltipOpen ? "" : "hidden"
                } ${theme ? "" : "next__tooltip_theme_light"}`}
              >
                <p className="next__tooltip-text">{language.secondTooltip}</p>
                <p className="next__quiestion">?</p>
              </div>
            </button>
          </div>
        </li>
        <li
          className={`next__list-item ${
            theme ? "" : "next__list-item_theme_light"
          }`}
        >
          <p className="next__number">03</p>
          <div className="next__list-box">
            <p className="next__action-word">{language.submit}</p>
            <p className="next__explanation">{language.submitSubline}</p>
            <button
              type="button"
              className={`next__button`}
              onClick={() => setThirdTooltipOpen(!isThirdTooltipOpen)}
            >
              {language.learnMore}
              <div
                className={`next__tooltip ${
                  isThirdTooltipOpen ? "" : "hidden"
                } ${theme ? "" : "next__tooltip_theme_light"}`}
              >
                <p className="next__tooltip-text">{language.thirdTooltip}</p>
                <p className="next__quiestion">?</p>
              </div>
            </button>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default WhatsNext;
