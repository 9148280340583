// Experience / CV
import webDevIconExp from "../images/icons/experience/present.svg";
import copyrightIconExp from "../images/icons/experience/copyright.svg";
import designIconExp from "../images/icons/experience/design.svg";

// Technologies
import htmlTech from "../images/icons/techs/html_white.svg";
import cssTech from "../images/icons/techs/css_white.svg";
import reactTech from "../images/icons/techs/react_white.svg";
import backendTech from "../images/icons/techs/backend_white.svg";
import JSTech from "../images/icons/techs/javascript_white.svg";
import nodeJSTech from "../images/icons/techs/nodejs.svg";
import reduxTech from "../images/icons/techs/redux_white.svg";
import nextJsTech from "../images/icons/techs/nextjs.svg";

// Skills
import reactIcon from "../images/icons/skills/react.svg";
import cssIcon from "../images/icons/skills/css.svg";
import htmlIcon from "../images/icons/skills/html.svg";
import graphicIcon from "../images/icons/skills/graphic.svg";
import backendIcon from "../images/icons/skills/backend.svg";
import reduxIcon from "../images/icons/skills/redux.svg";
import antdIcon from "../images/icons/skills/antd.svg";
import nextJSIcon from "../images/icons/skills/nextjs.svg";
import JSIcon from "../images/icons/skills/js.svg";

// Mockups
import colibriBusinessCard from "../images/mokups/colibri_business_card.png";
import colibriInsta from "../images/mokups/colibri_insta.png";
import DOBag from "../images/mokups/do_bag.png";
import ZGWallpaper from "../images/mokups/zg_phone.png";
import apelsinBag from "../images/mokups/apelsin_bag.png";
import BMCover from "../images/mokups/bm_covers.png";
import BurgerMac from "../images/mokups/sites/burger_mac.png";

// Websites
import colibriPhone from "../images/mokups/sites/colibri_phone.png";
import kardex from "../images/mokups/sites/kardex.png";
import moviesLaptopCards from "../images/mokups/sites/movies_laptop_cards.png";
import moviesBackend from "../images/mokups/sites/movies_backend.png";
import tbt from "../images/mokups/sites/2bt.png";

export const imgGallery = [
  {
    name: "Apelsin | Fitness Bar",
    image: apelsinBag
  },
  {
    name: "Colibri | Dental Clinic",
    image: colibriInsta
  },
  {
    name: "Digital Oasis | Tech Shop",
    image: DOBag
  },
  {
    name: "Dr. Zurab G. | Personal Brand",
    image: ZGWallpaper
  },
  {
    name: "Banana Mango SOW | MMA Club",
    image: BMCover
  },
  {
    name: "Colibri | Dental Clinic",
    image: colibriBusinessCard
  }
];

export const siteGallery = [
  {
    name: "React Burger | DnD Burger creator",
    image: BurgerMac,
    link: "https://react-burger-yunesb.vercel.app/",
    technologies: [cssTech, htmlTech, JSTech, reactTech, reduxTech]
  },
  {
    name: "Kardex Remstar Kazakhstan",
    image: kardex,
    link: "https://kardex.vercel.app/",
    technologies: [cssTech, htmlTech, JSTech, reactTech, nextJsTech]
  },
  {
    name: "Movies App | Backend",
    image: moviesBackend,
    link: "https://github.com/YunesB/movies-explorer-api",
    technologies: [JSTech, nodeJSTech, backendTech]
  },
  {
    name: "Movies App | Frontend",
    image: moviesLaptopCards,
    link: "https://github.com/YunesB/movies-explorer-frontend",
    technologies: [cssTech, htmlTech, JSTech, reactTech]
  },
  {
    name: "2BillionTrees.kz | Eco charity",
    image: tbt,
    link: "https://dev.2billiontrees.kz/",
    technologies: [cssTech, htmlTech, JSTech, reactTech, nextJsTech]
  },
  {
    name: "Colibri | Dental Clinic Price List",
    image: colibriPhone,
    link: "https://colibri-pricing-yunesb.vercel.app/therapy",
    technologies: [cssTech, htmlTech, JSTech, reactTech]
  }
];

export const createSkillsData = (language: any) => {
  return [
    {
      name: "CSS",
      icon: cssIcon
    },
    {
      name: "HTML",
      icon: htmlIcon
    },
    {
      name: "JavaScript",
      icon: JSIcon
    },
    {
      name: "React",
      icon: reactIcon
    },
    {
      name: "Redux",
      icon: reduxIcon
    },
    {
      name: "NextJS",
      icon: nextJSIcon
    },
    {
      name: language.backend,
      icon: backendIcon
    },
    {
      name: "Ant-Design",
      icon: antdIcon
    },
    {
      name: language.graphicDesign,
      icon: graphicIcon
    }
  ];
};

export const createExpData = (language: any) => {
  return [
    {
      image: webDevIconExp,
      date: language.experienceWebDate,
      name: language.experienceWebHeader,
      description: language.experienceWebBody,
      additionalInfo: language.experienceAdditionalInfo
    },
    {
      image: designIconExp,
      date: language.experienceDesignDate,
      name: language.experienceDesignHeader,
      description: language.experienceDesignBody
    },
    {
      image: copyrightIconExp,
      date: language.experienceGeneralDate,
      name: language.experienceGeneralHeader,
      description: language.experienceGeneralBody
    }
  ];
};
