import { useContext, createRef, useState, useEffect, FC } from "react";
import "./Contact.css";

import ScrollableAnchor from "react-scrollable-anchor";
import ContactForm from "./ContactForm";

import { LangContext, ThemeContext, ScrollContext } from "../../utils/contexts";

const Contact: FC = () => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);
  const scrollCoor = useContext(ScrollContext);

  const contactRef = createRef();
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (contactRef.current.offsetTop <= scrollCoor + 600) {
      setMount(true);
    }
  }, [contactRef, scrollCoor]);

  return (
    <section
      className={`contact ${
        isMount ? "mounted-component" : "hidden-component"
      }`}
      // @ts-ignore
      ref={contactRef}
    >
      <div className="contact__box">
        <div className="contact__text-container">
          <h2 className="contact__tagline section__name">
            {language.contactFind}
          </h2>
          <ScrollableAnchor id={"contact"}>
            <h3 className="contact__heading section__heading">
              {language.contact}
            </h3>
          </ScrollableAnchor>
          <ul className="contact__list">
            <li className="contact__list-item">
              <div className="contact__icon contact__icon_location"></div>
              <p className="contact__list-text">{language.distantWorking}</p>
              <p className="contact__data-text">{language.address}</p>
            </li>
            <li className="contact__list-item">
              <div className="contact__icon contact__icon_mail"></div>
              <p className="contact__list-text">{language.getInTouch}</p>
              <a
                href={`mailto:${language.mail}`}
                className="mailto hvr-underline-reveal contact__data-text"
              >
                {language.mail}
              </a>
            </li>
          </ul>
        </div>
        <div className="contact__form-container">
          <p className="contact__form-tagline">{language.contactMe}</p>
          <p
            className={`contact__form-text ${
              theme ? "" : "contact__form-text_theme_light"
            }`}
          >
            {language.letsCreate}
          </p>
          <p className="contact__form-subtext">{language.contactSubline}</p>
          <ContactForm language={language} theme={theme} />
        </div>
      </div>
    </section>
  );
};

export default Contact;
