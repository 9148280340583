import { FC, useState } from "react";
import linkIcon from "../../images/icons/link_icon.svg";
import loading from "../../images/icons/loading.svg";

type Card = {
  link: string;
  image: string;
  name: string;
  technologies: string[];
};

type Props = {
  card: Card;
};

const SiteGallery: FC<Props> = ({ card }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [image, setImage] = useState(loading);

  const handleImageLoading = () => {
    setImage(card.image);
    if (image === loading) {
      return;
    } else {
      setLoaded(true);
    }
  };

  return (
    <li className="portfolio__gallery-item portfolio__gallery-item_type_site">
      <img
        src={image}
        className={isLoaded ? "portfolio__image" : "portfolio__loading"}
        alt={isLoaded ? card.name : "Loading..."}
        onLoad={handleImageLoading}
      />
      <a
        href={card.link}
        className="protfolio__link"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkIcon} alt="Link Icon" className="portfolio__link-icon" />
        <div className="portfolio__container">
          <h3 className="protfolio__subline">{card.name}</h3>
          <ul className="portfolio__list">
            {card.technologies.map((item, index) => (
              <li className="protfolio__list-item" key={index}>
                <img src={item} alt="icon" className="portfolio__icon" />
              </li>
            ))}
          </ul>
        </div>
      </a>
    </li>
  );
};

export default SiteGallery;
