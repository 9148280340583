import { FC, useState } from "react";
import loading from "../../images/icons/loading.svg";

type Card = {
  image: string;
  name: string;
};

type Props = {
  card: Card;
  findImage: () => void;
};

const ImgGallery: FC<Props> = ({ card, findImage }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [image, setImage] = useState(loading);

  const handleImageLoading = () => {
    setImage(card.image);
    if (image === loading) {
      return;
    } else {
      setLoaded(true);
    }
  };

  return (
    <li className="portfolio__gallery-item" onClick={findImage}>
      <img
        src={image}
        className={isLoaded ? "portfolio__image" : "portfolio__loading"}
        alt={isLoaded ? card.name : "loading"}
        onLoad={handleImageLoading}
      />
    </li>
  );
};

export default ImgGallery;
