import { FC, useContext, useState } from "react";
import { TCard } from "./Experience";
import { motion } from "framer-motion";
import "./Experience.css";
import { LangContext } from "../../utils/contexts";

type Props = {
  card: TCard;
};

const ExperienceItem: FC<Props> = ({ card }) => {
  const { image, date, name, description } = card;
  const [isInfoShown, setInfoShown] = useState<boolean>(false);
  const language = useContext(LangContext);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const listItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  };

  return (
    <li className="experience__list-item">
      <div className="experience__list-box">
        <div className="experience__list-container">
          <img src={image} className="experience__icon" alt="exp icon" />
          <p className="experience__date">{date}</p>
        </div>
        <p className="experience__name">{name}</p>
      </div>
      <div className="experience__description">
        <p>{description}</p>
      </div>
      {card.additionalInfo && (
        <>
          <div
            className={`experience__height-control_hidden ${
              isInfoShown ? "experience__height-control" : ""
            }`}
          />
          <div className="experience__additional">
            <button
              type="button"
              className={"experience__add-button"}
              onClick={() => setInfoShown(!isInfoShown)}
            >
              <p className="experience__button-caption">
                {isInfoShown ? language.showLess : language.showMore}
              </p>
              <div
                className={`experience__add-icon ${
                  isInfoShown ? "experience__add-icon_alt" : ""
                }`}
              />
            </button>

            {isInfoShown && (
              <motion.ul
                className={`experience__add-list ${
                  isInfoShown ? "" : "experience__add-list_hidden"
                }`}
                variants={container}
                initial="hidden"
                animate="show"
              >
                {card.additionalInfo.map((info, index) => (
                  <motion.li
                    className="experience__add-list-item"
                    key={index}
                    variants={listItem}
                  >
                    {info}
                  </motion.li>
                ))}
              </motion.ul>
            )}
          </div>
        </>
      )}
    </li>
  );
};

export default ExperienceItem;
