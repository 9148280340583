import { useForm } from "@formspree/react";
import { Formik, Form, Field } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import Check from "./Check";

type Props = {
  language: any;
  theme: boolean;
};

const ContactForm: FC<Props> = ({ language, theme }: Props) => {
  const [state, handleSubmit] = useForm("xoqykwdw");

  const MailValidatiomSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, language.VALIDATION.SHORT_NAME)
      .max(20, "Too Long!")
      .required(language.VALIDATION.EMPTY_FIELD),
    message: Yup.string()
      .min(2, language.VALIDATION.SHORT_MESSAGE)
      .max(50, "Too Long!")
      .required(language.VALIDATION.EMPTY_FIELD),
    email: Yup.string()
      .email(language.VALIDATION.INCORRECT_EMAIL)
      .required(language.VALIDATION.EMPTY_FIELD)
  });

  if (state.succeeded) {
    return (
      <div className="submit__box">
        <p className="submit__text">{language.onSubmitMessage}</p>
        <Check />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: ""
      }}
      validationSchema={MailValidatiomSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid }) => (
        <Form className="contact__form">
          <div className="contact__form-box">
            <label className="contact__label">
              <p
                className={`contact__text-label ${
                  theme ? "" : "contact__text-label_theme_light"
                }`}
              >
                {language.yourName}
              </p>
              <Field
                type="text"
                id="name"
                name="name"
                className={`contact__input ${
                  theme ? "" : "contact__input_theme_light"
                }`}
              />
              {errors.name && touched.name ? (
                <span className="contact__input-error">{errors.name}</span>
              ) : null}
            </label>
            <label className="contact__label">
              <p
                className={`contact__text-label ${
                  theme ? "" : "contact__text-label_theme_light"
                }`}
              >
                {language.yourMail}
              </p>
              <Field
                type="email"
                id="email"
                name="email"
                className={`contact__input ${
                  theme ? "" : "contact__input_theme_light"
                }`}
              />
              {errors.email && touched.email ? (
                <span className="contact__input-error">{errors.email}</span>
              ) : null}
            </label>
          </div>
          <label className="contact__textarea-label">
            <p
              className={`contact__text-label ${
                theme ? "" : "contact__text-label_theme_light"
              }`}
            >
              {language.yourMessage}
            </p>
            <Field
              as="textarea"
              type="text"
              id="message"
              name="message"
              className={`contact__textarea ${
                theme ? "" : "contact__input_theme_light"
              }`}
            />
            {errors.message && touched.message ? (
              <span className="contact__input-error">{errors.message}</span>
            ) : null}
          </label>
          <button
            type="submit"
            className={`contact__submit ${
              isValid ? "" : "contact__submit_disabled"
            } ${theme ? "" : "contact__submit_theme_light"}`}
          >
            {language.sendMessage}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
