import { FC } from "react";
import "./Footer.css";
import { links } from "../../utils/texts";

const Footer: FC = () => {
  return (
    <footer className="footer">
      <ul className="footer__list">
        <li className="footer__list-item">
          <a
            href={links.instagram}
            target="_blank"
            rel="noreferrer"
            className="footer__link footer__link_type_instagram"
          >
            <span className="hidden">Instagram</span>
          </a>
        </li>
        <li className="footer__list-item">
          <a
            href={links.github}
            target="_blank"
            rel="noreferrer"
            className="footer__link footer__link_type_github"
          >
            <span className="hidden">GitHub</span>
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
