import "./Skills.css";
import { motion } from "framer-motion";

const Skill = ({ theme, variants, card }) => {
  return (
    <motion.li
      className={`skills__list-item ${
        theme ? "" : "skills__list-item_theme_light"
      }`}
      variants={variants}
    >
      <div className="skills__box hvr-icon-grow-rotate">
        <img
          src={card.icon}
          alt={`${card.name} icon`}
          className="skills__icon"
        />
        <p className="skills__text">{card.name}</p>
      </div>
      <p className="skills__percentage">✓</p>
    </motion.li>
  );
};

export default Skill;
