import { FC, useContext } from "react";
import { LangContext } from "../../utils/contexts";
import "./MobileBar.css";

import arrowUpIcon from "../../images/icons/up_arrow_icon.svg";
import themeIconMobile from "../../images/icons/mobile_theme_icon.svg";

type Props = {
  changeLanguage: () => void;
  changeTheme: () => void;
};

const MobileBar: FC<Props> = ({ changeLanguage, changeTheme }) => {
  const language = useContext(LangContext);

  return (
    <section className="mobile-bar">
      <ul className="mobile-bar__list">
        <li className="mobile-bar__list-item">
          <a href="#header" className="mobile-bar__link">
            <img
              src={arrowUpIcon}
              className="mobile-bar__button-icon"
              alt="arrow icon"
            />
          </a>
        </li>
        <li className="mobile-bar__list-item">
          <button className="mobile-bar__button" onClick={changeLanguage}>
            {language.lang}
          </button>
        </li>
        <li className="mobile-bar__list-item">
          <button className="mobile-bar__button" onClick={changeTheme}>
            <img
              src={themeIconMobile}
              className="mobile-bar__button-icon"
              alt="theme icon"
            />
          </button>
        </li>
      </ul>
    </section>
  );
};

export default MobileBar;
