import { useContext, createRef, useState, useEffect, useMemo, FC } from "react";
import "./Experience.css";

import ExperienceItem from "./ExperienceItem";
import { createExpData } from "../../utils/arrays";
import { LangContext, ThemeContext, ScrollContext } from "../../utils/contexts";

export type TCard = {
  image: string;
  date: string;
  name: string;
  description: string;
  additionalInfo?: string[];
};

const Experience: FC = () => {
  const language = useContext(LangContext);
  const theme = useContext(ThemeContext);
  const scrollCoor = useContext(ScrollContext);

  const experienceRef = createRef();
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (experienceRef.current.offsetTop <= scrollCoor + 600) {
      setMount(true);
    }
  }, [experienceRef, scrollCoor]);

  const listData: TCard[] = useMemo(() => createExpData(language), [language]);

  return (
    <section
      className={`experience ${
        theme ? "background-color-dark" : "background-color-light"
      } ${isMount ? "mounted-component" : "hidden-component"}`}
      // @ts-ignore
      ref={experienceRef}
    >
      <div className="experience__section-container">
        <div className="experience__text-container">
          <h2 className="experience__tagline section__name">
            {language.workinWith}
          </h2>
          <h3 className="experience__heading section__heading">
            {language.experience}
          </h3>
          <p className="experience__text">{language.experienceSubline}</p>
        </div>
        <ul className="experience__list">
          {listData.map((card, index) => (
            <ExperienceItem card={card} key={index} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Experience;
